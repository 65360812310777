import { useShoppingCartStore } from "@/stores/shoppingCart";

export const useShoppingCart = () => {
  const runtimeConfig = useRuntimeConfig();
  const { $router } = useNuxtApp();
  const store = useShoppingCartStore();
  const {
    createCustomer,
    getCustomerByEmail,
    createCheckoutSession,
    itemPrice,
    parsePrice,
    priceValue,
  } = useStripe();

  function addToCart(item: object) {
    store.addItem(item);
  }

  function removeItem(item: object) {
    store.removeItem(item);
  }

  function clear() {
    store.clear();
  }

  function itemTotalPrice(item: object) {
    return parsePrice(item.quantity * priceValue(item.price));
  }

  const { items } = storeToRefs(store);
  const totalPrice = computed(() => {
    let total = 0;
    items.value.forEach((item) => {
      total += item.quantity * priceValue(item.price);
    });

    return parsePrice(total);
  });

  const totalItems = computed(() => {
    let total = 0;
    items.value.forEach((item) => {
      total += item.quantity;
    });

    return total;
  });

  async function checkout() {
    const customer = {
      id: null,
      email: "",
    };

    const user = await createCustomer({
      stripeCustomerId: customer?.id,
      email: customer.email,
    });

    const stripeItems = items.value.map((item) => {
      return {
        price: item.price.id,
        quantity: item.quantity,
      };
    });
    const checkoutSession = await createCheckoutSession(stripeItems, user, {
      success_url: `${runtimeConfig.public.siteUrl || "https://localhost:3000"}/checkout-success?session_id={CHECKOUT_SESSION_ID}"`,
      cancel_url: `${runtimeConfig.public.siteUrl || "https://localhost:3000"}${$router.currentRoute.value.fullPath}?cancel=true`,
      // return_url: `${runtimeConfig.public.siteUrl || "https://localhost:3000"}${$router.currentRoute.value.fullPath}?session_id={CHECKOUT_SESSION_ID}"`,
    });

    window.open(checkoutSession.url, "_self");
  }

  return {
    clear,
    addToCart,
    removeItem,
    items,
    itemTotalPrice,
    totalPrice,
    totalItems,
    checkout,
  };
};
