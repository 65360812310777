import { defineStore } from "pinia";

interface blogTypeDe {
  items: any[];
}

export const useShoppingCartStore = defineStore(
  "shoppingCart",
  () => {
    const { parsePrice, priceValue } = useStripe();
    const state: blogTypeDe = reactive({
      items: [],
    });

    function clear() {
      state.items = [];
    }

    function addStats(item: object) {
      item.quantity = item.quantity ? item.quantity + 1 : 1;
      item.singlePrice = parsePrice(priceValue(item.price));
    }

    function addItem(item: object) {
      const cartItem = state.items.find((i) => i.uuid === item.uuid);
      if (cartItem) {
        addStats(cartItem);
      } else {
        addStats(item);
        state.items.push(item);
      }
    }

    function removeItem(item: object) {
      const cartItem = state.items.find((i) => i.uuid === item.uuid);
      if (cartItem) {
        state.items.splice(state.items.indexOf(cartItem), 1);
      }
    }

    return {
      ...toRefs(state),
      clear,
      addItem,
      removeItem,
    };
  },
  { persist: true },
);
